import React from 'react';
import './ExistObserversList.scss';
import SendIcon from "components/UI/Icons/SendIcon";

const ExistObserversList = ({observers}) => {
  return (
    <div className='exist-observer__wrapper'>
      <div className="exist-observers__list">
        {observers?.map((observer) => (
          <div key={observer.id} className="exist-observer__item">
            <SendIcon color="#3f56d3" /> {observer.name} {observer.surname}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExistObserversList;
