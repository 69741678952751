import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';

import './AuthForms.scss';

import MyButton from 'components/UI/Button/MyButton';
import RegistrationFormField from '../FormFields/RegistrationFormField/RegistrationFormField';
import { registrationSchema } from '../ValidationSchemas/AuthFormsValidationSchemas';
import { useActions } from 'hooks/useActions';
import RegistrationCheckbox from '../FormFields/RegistrationCheckbox/RegistrationCheckbox';
import { routePaths } from 'constants/routes';

const RegistrationForm = ({ campaignCode }) => {
  const navigate = useNavigate();
  const { HOME } = routePaths;

  const { registrationRequest } = useActions();

  return (
    <div className='auth-form__wrapper'>
      <Formik
        initialValues={{
          name: '',
          surname: '',
          email: '',
          password: '',
          privacy: '',
        }}
        validationSchema={registrationSchema}
        onSubmit={(values) => {
          registrationRequest({
            ...values,
            campaign_code: campaignCode ? campaignCode : '',
          }, navigate(HOME));
        }}
      >
        {({ dirty, isValid }) => (
          <Form className='auth-form'>
            <Field
              name='name'
              type='text'
              label='First name'
              component={RegistrationFormField}
            />
            <Field
              name='surname'
              type='text'
              label='Last name'
              component={RegistrationFormField}
            />
            <Field
              name='email'
              type='email'
              label='Email'
              component={RegistrationFormField}
            />
            <Field
              name='password'
              type='password'
              label='Password'
              component={RegistrationFormField}
            />
            <Field name='privacy' component={RegistrationCheckbox} />
            <MyButton
              type='submit'
              className='create-account'
              disabled={!dirty || !isValid}
            >
              CREATE ACCOUNT
            </MyButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationForm;
