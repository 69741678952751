import { GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_ERROR } from "redux/constants"

export function getCampaigns(token = '') {
    return {
        type: GET_CAMPAIGNS,
        payload: token
    }
}

export function getCampaignsSuccess(campaigns) {
    return {
        type: GET_CAMPAIGNS_SUCCESS,
        payload: campaigns
    }
}

export function getCampaignsError(error) {
    return {
        type: GET_CAMPAIGNS_ERROR,
        payload: error
    }
}