export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const REGISTRATION = `${API_BASE_URL}student/register`;
export const LOGIN = `${API_BASE_URL}student/login`;

export const CAMPAIGNS = {
    LIST: `${API_BASE_URL}student/campaigns`,
    SINGLE_CAMPAIGN: (id) => `${API_BASE_URL}student/campaigns/${id}`,
    QUESTIONNAIRE: (id) => `${API_BASE_URL}student/campaigns/${id}/questionnaire`,
};

export const SELECT_LANGUAGE = `${API_BASE_URL}user/preferred-language`;

export const CHECK_OBSERVER = (code) => `${API_BASE_URL}observer/check?observer_code=${code}`;

export const OBSERVER_QUESTIONNAIRE = (code) => `${API_BASE_URL}observer/questionnaire?observer_code=${code}`;
