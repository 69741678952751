import React from 'react';
import './CampaignObservers.scss';
import ObserversList from './ObserversList/ObserversList';
import { observerItemColors } from "helpers/ObserverItemColors";


const CampaignObservers = ({ observerTypes, observers, report, campaignStatus }) => {
  
  return (
    <section className='campaign-observers'>
      {observerTypes
        ?.sort((a, b) => b['required'] - a['required'])
        .map((type, index) => (
          <ObserversList
            key={index}
            type={type.name}
            observers={observers.filter(
              (observer) => observer.observer_type_id === type.id
            )}
            colors={observerItemColors[index]}
            report={report}
            campaignStatus={campaignStatus}
          />
        ))}
    </section>
  );
};

export default CampaignObservers
