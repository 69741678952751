import React from 'react';
import './MyButton.scss';

const MyButton = ({ children, className, ...props }) => {
  return (
    <button {...props} className={className ? `MyButton MyButton__${className}` : 'MyButton'}>
      {children}
    </button>
  );
};

export default MyButton;
