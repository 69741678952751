import React from "react";
import Slider from "react-slick";
import ObserverItem from "pages/CampaignPage/CampaignObservers/ObserverItem/ObserverItem";

const ObserversSlider = ({ observers, colors }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    slide: ".observer-item",
  };

  return (
    <div className='observers__slider'>
      <Slider {...settings}>
        {observers.length > 0 &&
          observers.map((observer, index) => (
            <ObserverItem
              key={index}
              colors={colors}
              name={observer.name}
              surname={observer.surname}
              email={observer.email}
              date={observer.date}
              isAnswered={observer.is_answered}
              invitationLink={observer.questionnaire_url}
            />
          ))}
      </Slider>
    </div>
  );
};

export default ObserversSlider;
