export const questionnaireSelect = {
    control: (provided, state) => {
        let borderColor = state.menuIsOpen ? '#3D8B82' : '#CDCBCB';

        return {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '500px',
            height: '51px',
            padding: '8px 16px',
            borderRadius: '12px',
            border: '1px solid #CDCBCB',
            borderColor: borderColor,
            backgroundColor: 'white',
            color: '#101821',
            cursor: 'pointer',

            // "@media(min-width: 620px)": {
            //     padding: '8px 20px 8px 15px',
            // },
            // "@media(min-width: 1500px)": {
            //     padding: '8px 48px 8px 24px',
            // }
        }
    },
    valueContainer: (provided) => ({
        ...provided,
        padding: 0
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '17px',
        lineHeight: '24px',
        color: '#9555555',
        letterSpacing: '0.0015em',

        "@media(min-width: 620px)": {
            fontSize: '18px',
            lineHeight: '23px',
        }
    }),
    // indicatorsContainer: () => ({
    //     display: 'flex',
    //     position: 'absolute',
    //     right: '10px',
    //     top: 'calc(50%)',
    //     cursor: 'pointer',
    //     background: 'rgba(255, 255, 255, 0.1)'
    // }),
    dropdownIndicator: (base, state) => ({
        ...base,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    menu: (provided) => ({
        ...provided,
        top: '56px',
        border: '1px solid #CDCBCB',
        margin: '0',
        padding: '16px',
        background: '#fff',
        boxShadow: '0px 12px 24px rgba(209, 207, 205, 0.4)',
        borderRadius: '12px',
        zIndex: 10,
        maxWidth: '500px',

        "@media(min-width: 620px)": {
            top: '56px',
            padding: '16px 12px',
        }
    }),
    menuList: (base) => ({
        ...base,
        background: 'transparent',
        maxHeight: "300px",
        maxWidth: '500px',
        zIndex: 1,

    }),
    option: (provided) => ({
        ...provided,
        color: "#101821",
        backgroundColor: "#fff",
        padding: '5px 8px',
        margin: '0',
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '24px',
        borderRadius: '12px',

        "@media(min-width: 768px)": {
            padding: '8px 12px',
            fontSize: '17px',
            lineHeight: '24px',
        },

        ":hover": {
            backgroundColor: "#F5F5F5",
            cursor: 'pointer'
        }
    })
};