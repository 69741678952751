import React, { useState } from "react";
import "./ObserverInfo.scss";

import MyButton from "components/UI/Button/MyButton";
import InvitationDetails from "../InvitationDetails/InvitationDetails";
import Modal from "components/UI/Modal/Modal";
import LanguageSelection from "components/LanguageSelection/LanguageSelection";
import SuccessMessage from "components/SuccessMessage/SuccessMessage";

const ObserverInfo = ({ data, code, error }) => {
  const [languagesModalActive, setLanguagesModalActive] = useState(false);

  if (error === "access_denied") {
    return (
      <div className="observer-message__wrapper">
        <SuccessMessage />
      </div>
    );
  }

  if (error && error !== "access_denied") {
    return null;
  }

  return (
    <div className="observer__container">
      <div className="observer__title">Welcome,</div>
      <div className="observer__name">
        {data?.observer_name} {data?.observer_surname}!
      </div>
      <InvitationDetails
        name={`${data?.student_name} ${data?.student_surname}`}
        dueDate={data?.campaign_deadline}
      />
      <MyButton
        className="continue full-width"
        onClick={() => setLanguagesModalActive(true)}
      >
        Continue
      </MyButton>
      <Modal active={languagesModalActive} setActive={setLanguagesModalActive}>
        <LanguageSelection
          active={languagesModalActive}
          setActive={setLanguagesModalActive}
          token={data?.observer_token}
          navLink={`/observer/survey`}
          navState={{
            code: code ? code : "",
            token: data?.observer_token ? data.observer_token : "",
          }}
        />
      </Modal>
    </div>
  );
};

export default ObserverInfo;
