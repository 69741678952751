import React from 'react'

const SendIcon = ({ color = "#3F56D3" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8627 1.15166C16.4458 0.723837 15.8289 0.564453 15.2537 0.732225L1.83983 4.63294C1.23292 4.80155 0.802743 5.28558 0.686862 5.90047C0.568481 6.52626 0.981983 7.32066 1.5222 7.65285L5.71642 10.2307C6.1466 10.4949 6.70183 10.4287 7.0578 10.0696L11.8606 5.23692C12.1024 4.98526 12.5025 4.98526 12.7443 5.23692C12.9861 5.48019 12.9861 5.87446 12.7443 6.12612L7.93316 10.9597C7.57635 11.3178 7.50966 11.8757 7.77226 12.3085L10.335 16.5448C10.6351 17.0481 11.152 17.3333 11.7189 17.3333C11.7856 17.3333 11.8606 17.3333 11.9273 17.3249C12.5776 17.2411 13.0944 16.7965 13.2862 16.1673L17.2628 2.77066C17.4379 2.20024 17.2795 1.57948 16.8627 1.15166Z"
        fill={color}
      />
    </svg>
  );
};

export default SendIcon
