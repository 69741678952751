import { combineReducers } from "redux";
import { LOGOUT } from "redux/constants";
import { authReducer } from "./auth";
import { campaignsReducer } from "./campaigns";
import { singleCampaignReducer } from "./singleCampaign";


const appReducer = combineReducers({
    auth: authReducer,
    campaigns: campaignsReducer,
    singleCampaign: singleCampaignReducer
})

export const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined
    }

    return appReducer(state, action);
}