export const routePaths = {
    HOME: '/',
    REGISTRATION: '/registration/:code',
    SIGN_IN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    CHECK_EMAIL: '/forgot-password/check-email',
    CREATE_PASSWORD: '/create-password',
    CAMPAIGN_PAGE:'/campaign/:id',
    CAMPAIGN_QUESTIONNAIRE:'/campaign/:id/self-assessment',
    CAMPAIGN_OBSERVERS: '/campaign/:id/observers',
    OBSERVER_LANDING_PAGE: '/observer/questionnaire',
    OBSERVER_SURVEY: '/observer/survey',
}