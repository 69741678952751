import axios from 'axios';
import { fork, put, call, takeLatest } from "redux-saga/effects";

import { CAMPAIGNS } from 'constants/api';
import { getSingleCampaignSuccess, getSingleCampaignError } from 'redux/actions/singleCampaign';
import { GET_SINGLE_CAMPAIGN } from "redux/constants";
import ResponseErrorHandler from 'helpers/ResponseErrorHandler';
import { notification } from 'helpers/notification';


function* watchGetSingleCampaign() {
    yield takeLatest(GET_SINGLE_CAMPAIGN, handleGetSingleCampaign)
}

function* handleGetSingleCampaign({ payload }) {
    const { userToken, id } = payload;

    try {
        const { data } = yield call(() => axios.get(CAMPAIGNS.SINGLE_CAMPAIGN(id),
            { 'headers': { 'Authorization': `Bearer ${userToken}` } })
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            yield put(getSingleCampaignSuccess(data.data))
            if (data.data.status === 'Finished') {
                notification('info', 'This campaign has already been finished')
            }
        }

        if (data?.status === 'Error') {
            const err = new ResponseErrorHandler(data);

            yield put(getSingleCampaignError(err.getValidationErrorMessages()))
        }

    } catch (error) {
        yield put(getSingleCampaignError(error.message))
    }
}

export default function* getSingleCampaignSaga() {
    yield fork(watchGetSingleCampaign)
}
