import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './CampaignPage.scss';

import TitleSection from 'components/TitleSection/TitleSection';
import profileImage from 'assets/images/profile_image.svg';
import StepItems from './StepItems/StepItems';
import CampaignObservers from './CampaignObservers/CampaignObservers';
import ReportSection from './ReportSection/ReportSection';
import { useActions } from 'hooks/useActions';
import { useSelector } from 'react-redux';
import Loader from 'components/UI/Loader/Loader';
import { dateTransform } from 'helpers/dateTransform';

const CampaignPage = () => {
  const { id } = useParams();
  const { userToken } = useSelector((state) => state.auth);
  const { campaign, loading } = useSelector((state) => state.singleCampaign);

  const { getSingleCampaign } = useActions();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!campaign || campaign?.id !== +id) {
      getSingleCampaign({ userToken, id });
    }
  }, [campaign, id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <section className='campaign-page'>
      {campaign && (
        <div key={campaign.id}>
          <TitleSection
            title={campaign.name}
            description={campaign.description}
            image={profileImage}
          />
          {campaign.report_url ? (
            <ReportSection url={campaign?.report_url} />
          ) : (
            <StepItems
              self_assessment={campaign.self_assessment_questionnaire_filled}
              completed_date={
                campaign.self_assessment_questionnaire_filled_at
                  ? dateTransform(
                      campaign.self_assessment_questionnaire_filled_at
                    )
                  : ''
              }
              observers={campaign.observers}
              observerTypes={campaign.observer_types}
              campaignStatus={campaign.status}
            />
          )}
          {campaign.observers.length > 0 &&
            campaign.self_assessment_questionnaire_filled && (
              <CampaignObservers
                observerTypes={campaign.observer_types}
                observers={campaign.observers}
                report={campaign.report}
                campaignStatus={campaign.status}
              />
            )}
        </div>
      )}
    </section>
  );
};

export default CampaignPage;
