import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ObserversInfo from './helpers/ObserversInfo';
import ParticipantsInfo from './helpers/ParticipantsInfo';

const InformativePanel = () => {
  const { state } = useLocation();

  const { userToken } = useSelector((state) => state.auth);

  return (
    <div className='informative-panel__wrapper'>
      {userToken ? (
        <ParticipantsInfo language={state?.language} />
      ) : (
        <ObserversInfo language={state?.language} />
      )}
    </div>
  );
};

export default InformativePanel;
