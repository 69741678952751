import React from "react";
import "./Checkbox.scss";

const Checkbox = ({ name, field, label, values, value, onChange}) => {
  return (
    <label
      className={`checkbox__item ${
        values[name].includes(value) ? "checkbox__item--checked" : ""
      }`}
    >
      <div className="checkbox__item-box"></div>
      {label && <span className="checkbox__item-label">{label}</span>}
      <input
        type="checkbox"
        className="checkbox__item-input"
        name={name}
        value={value}
        checked={values[name].includes(value)}
        onChange={() => onChange(name, value, field)}
      />
    </label>
  );
};

export default Checkbox;
