import { toast } from 'react-toastify';

export const notification = (type, message, style) => {
    const generalProps = {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: style
    }

    switch (type) {
        case 'success':
            return toast.success(message, {
                ...generalProps
            });
        case 'error':
            return toast.error(message, {
                ...generalProps
            });
        case 'info':
            return toast.info(message, {
                ...generalProps
            });
        default:
            break;
    }
}
