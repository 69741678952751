import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import './AddObserversForm.scss';

import { API_BASE_URL } from 'constants/api';
import FormTitleSection from './FormTitleSection/FormTitleSection';
import StepsContent from './StepContent/StepsContent';
import { addObserversFormValidationSchema } from '../ValidationSchemas/AddObserversFormValidationSchema';
import ResponseErrorHandler from 'helpers/ResponseErrorHandler';
import { useActions } from 'hooks/useActions';
import { notification } from 'helpers/notification';

const AddObserversForm = ({ campaign }) => {
  const [steps, setSteps] = useState([]);
  const [activeFormStep, setActiveFormStep] = useState(steps[0]);

  const { state } = useLocation();

  const { id } = useParams();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.auth);

  const { getSingleCampaign } = useActions();

  const [initialValues, setInitialValues] = useState(false);

  const { observer_types, observers } = campaign;

  const renderFormContent = (item, values, observers) => {
    return (
      <StepsContent
        name={item.id}
        values={values}
        type={item}
        observers={observers}
      />
    );
  };

  const changeFormStep = (item) => {
    setActiveFormStep(observer_types.find((type) => type.id === item.id));
  };

  const handleSubmit = async (values, actions) => {
    let userObservers = [];

    for (const key in values) {
      const newValue = [...values[key]];

      userObservers.push(newValue);
    }

    const newUserObserversArray = userObservers.flat(Infinity);

    const formData = {
      observers: newUserObserversArray.filter(
        (item) => item.name !== '' && item.surname !== '' && item.email !== ''
      ),
    };

    console.log(formData);

    if (formData.observers.length === 0) {
      notification(
        'info',
        'If you want to add an observer, you must add at least one and specify its data'
      );
    }

    if (formData.observers.length > 0) {
      // use to show a notification when the user tries to add only one observer, in the case, when he doesn't have any existing observer of that type
      if (
        observers.filter(
          (observer) => observer.observer_type_id === activeFormStep.id
        ).length < 1 &&
        !activeFormStep.required &&
        (values[activeFormStep.id][0].name !== '' ||
          values[activeFormStep.id][0].surname !== '' ||
          values[activeFormStep.id][0].email !== '') &&
        (values[activeFormStep.id][1].name === '' ||
          values[activeFormStep.id][1].surname === '' ||
          values[activeFormStep.id][1].email === '')
      ) {
        notification(
          'error',
          `In case you want to add ${activeFormStep.name} you have to add at least 2 and fill in all fields`
        );
      } else {
        // when the user add the correct number of observers and fill in all fields we send that data
        const { data } = await axios({
          method: 'post',
          url: `${API_BASE_URL}student/campaigns/${id}/observers`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          accept: 'application/json',
          data: formData,
        })
          .then((data) => data)
          .catch((error) => error.response);

        if (data.status === 'Success') {
          if (data.message === 'OK' || data.message === '') {
            notification('success', 'Observers have been successfully added');
          } else {
            notification('success', data.message, {
              width: window.innerWidth > 500 ? 'min-content' : '100vw',
              right: window.innerWidth > 500 ? '4em' : 0,
            });
          }
          navigate(`/campaign/${id}`);
          getSingleCampaign({ userToken, id });
        }

        if (data?.status === 'Error') {
          if (data?.errors?.length) {
            const responseErrors = {};

            responseErrors[activeFormStep.id] = [];

            data.errors.map((error) => {
              const index = error.field.match(/\d+/)[0];
              return (responseErrors[activeFormStep.id][+index] = {
                email: 'Field has a duplicate value',
              });
            });

            actions.setErrors(responseErrors);
          }

          const err = new ResponseErrorHandler(data);

          notification('error', err.getValidationErrorCustomMessages());
        }
      }
    }

    actions.setSubmitting(false);
  };

  useEffect(() => {
    // we should not display form tab with observer type if there are already 4 observers of that type
    const formSteps = observer_types
      .filter(
        (type) =>
          observers.filter((observer) => observer.observer_type_id === type.id)
            .length < 4
      )
      .sort((a, b) => b['required'] - a['required']);

    setSteps(formSteps);

    // create initialValues of the form depending on number of observer types
    const initial = {};
    for (let i in observer_types) {
      let obj = observer_types[i];

      // we can add only 4 observers to each type, so if there are 3 existing observers we have to display only 1 form field for this observer type
      if (
        observers.filter((observer) => observer.observer_type_id === obj.id)
          .length > 2
      ) {
        initial[obj.id] = [
          {
            name: '',
            surname: '',
            email: '',
            observer_type_id: observer_types[i].id,
          },
        ];
      } else {
        initial[obj.id] = [
          {
            name: '',
            surname: '',
            email: '',
            observer_type_id: observer_types[i].id,
          },
          {
            name: '',
            surname: '',
            email: '',
            observer_type_id: observer_types[i].id,
          },
        ];
      }
    }
    setInitialValues(initial);
    if (state) {
      setActiveFormStep(observer_types.find((type) => type.name === state));
    } else {
      setActiveFormStep(formSteps[0]);
    }
  }, []);

  return (
    <div className='observers-form__section'>
      {initialValues && activeFormStep && campaign && steps && (
        <Formik
          initialValues={initialValues}
          validationSchema={addObserversFormValidationSchema(
            activeFormStep,
            observers.filter(
              (observer) => observer.observer_type_id === activeFormStep.id
            )
          )}
          onSubmit={handleSubmit}
        >
          {({ values, validateForm, errors }) => {
            return (
              <>
                <FormTitleSection
                  totalCount={observers?.length}
                  tabs={steps}
                  activeTab={activeFormStep}
                  setActiveTab={changeFormStep}
                  validateForm={validateForm}
                  observers={observers}
                />
                <Form id='add-observers' className='form__container'>
                  {activeFormStep &&
                    renderFormContent(
                      activeFormStep,
                      values,
                      observers.filter(
                        (observer) =>
                          observer.observer_type_id === activeFormStep.id
                      )
                    )}
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default AddObserversForm;
