import React from 'react'

const FilledIcon = ({ color = '#3F56D3' }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.7674 0H6.23256C2.79057 0 0 2.79066 0 6.23256V13.7907C0 17.2093 2.79066 20 6.23256 20H13.7907C17.2093 20 20 17.2093 20 13.7674V6.23256C20 2.79057 17.2093 0 13.7674 0ZM8.86046 13.9535L4.69767 9.7907L6.02334 8.46503L8.88376 11.3255L14.4652 5.74406L15.7908 7.06973L8.86046 13.9535Z'
        fill={color}
      />
    </svg>
  );
};

export default FilledIcon