import React, { useEffect } from "react";
import TitleSection from "components/TitleSection/TitleSection";
import profileImage from "assets/images/profile_image.svg";
import CampaignItem from "components/CampaignItem/CampaignItem";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";
import campaignImage from "assets/images/campaign_image.svg";
import { dateTransform } from "helpers/dateTransform";
import "./Home.scss";

const Home = () => {
  const { userName, userToken } = useSelector((state) => state.auth);
  const { campaigns } = useSelector((state) => state.campaigns);

  const { getCampaigns } = useActions();

  useEffect(() => {
    getCampaigns(userToken);
  }, [userToken]);

  return (
    <div className='home__container'>
      <TitleSection
        title={`Hi, ${userName}!`}
        description='This page lists all META 360 campaigns to which you have been registered. Click any campaign to see more details.'
        image={profileImage}
      />
      <div className='campaigns__list'>
        {campaigns?.map((campaign) => (
          <CampaignItem
            key={campaign.id}
            title={campaign.name}
            description={campaign.description}
            image={campaign.logo ? campaign.logo : campaignImage}
            date={dateTransform(campaign.deadline)}
            status={campaign.status}
            to={`/campaign/${campaign.id}`}
            inviteLink={campaign.invite_link}
            registeredAt={campaign.registered_at}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
