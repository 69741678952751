import React from "react";
import "./Radio.scss";

import selectedRadioImage from "assets/images/radio_selected.svg";

const Radio = ({
  selected,
  onChange,
  value,
  text,
  showValue = false,
  color = "#5BCBC9",
  backgroundColor = "rgba(91, 203, 201, 0.2)",
}) => {
  // props showValue used to display the value of the radio button in the middle of the circle (questions type 'range')
  return (
    <div
      className="modern-radio-container"
      onClick={() => {
        onChange(value);
      }}
    >
      <div
        className={`radio-outer-circle ${value !== selected && "unselected"}`}
        style={{
          backgroundColor: value !== selected ? backgroundColor : color,
        }}
      >
        <div className="radio-inner-circle" style={{ backgroundColor: color }}>
          {value !== selected ? (
            showValue ? (
              value + 1
            ) : (
              ""
            )
          ) : (
            <img src={selectedRadioImage} alt="" />
          )}
        </div>
      </div>
      {text && <div className="helper-text">{text}</div>}
    </div>
  );
};

export default Radio;
