import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./AddObserversModalContent.scss";

import closeIcon from "assets/images/close_icon.svg";
import addPeople from "assets/images/add_people.svg";
import MyButton from "components/UI/Button/MyButton";

const AddObserversModalContent = ({ setActive, requiredTypes }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const requiredObserverNames = (types) => {
    types = types || [];

    switch (types.length) {
      case 0:
        return "2 observers of the same type";
      case 1:
        return `2 ${types[0]}`;
      case 2:
        return `2 ${types[0]} and 2 ${types[1]}`;
      default:
        return types
          .map((type) => {
            if (type === types[types.length - 1]) {
              return `and 2 ${type}`;
            } else if (type === types[types.length - 2]) {
              return `2 ${type}`;
            } else {
              return `2 ${type},`;
            }
          })
          .join(" ");
    }
  };

  return (
    <div className="add-observers-modal__wrapper">
      <img
        className="close__btn"
        src={closeIcon}
        alt="close-icon"
        onClick={() => setActive(false)}
      />
      <div className="add-observers-modal__content">
        <div className="content__icon">
          <img src={addPeople} alt="add_people_icon" />
        </div>
        <div className="content__title">Add Observers</div>

        <div className="content__description">
          You have to add minimum{" "}
          <span>
            {requiredObserverNames(requiredTypes.map((type) => type.name))}
          </span>{" "}
          to proceed
        </div>

        <div className="content__buttons">
          <MyButton
            onClick={() => navigate(`/campaign/${id}/observers`)}
            className="continue"
          >
            Ok, got it
          </MyButton>

          <div className="add-later__button" onClick={() => setActive(false)}>
            Add observers later
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddObserversModalContent;
