import * as Yup from 'yup';

export const addObserversFormValidationSchema = (item, observers) => {

    if (item.required && observers.length === 0) {
        return Yup.object().shape({
            [item.id]: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().trim()
                            .test('test-0', 'Field is required', function (value) {
                                return this.path !== `${item.id}[0].name` || value;
                            })
                            .test('test-1', 'Field is required', function (value) {
                                return this.path !== `${item.id}[1].name` || value;
                            }),
                        surname: Yup.string().trim()
                            .test('test-0', 'Field is required', function (value) {
                                return this.path !== `${item.id}[0].surname` || value;
                            })
                            .test('test-1', 'Field is required', function (value) {
                                return this.path !== `${item.id}[1].surname` || value;
                            }),
                        email: Yup.string().trim()
                            .email("Invalid email")
                            .test('test-0', 'Field is required', function (value) {
                                return this.path !== `${item.id}[0].email` || value;
                            })
                            .test('test-1', 'Field is required', function (value) {
                                return this.path !== `${item.id}[1].email` || value;
                            }),
                    })
                )
                .min(2, "Need to add at least 2 observers")
        })
    }

    if (observers.length >= 2) {
        return Yup.object().shape({
            [item.id]: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().trim(),
                        surname: Yup.string().trim(),
                        email: Yup.string().trim().email("Invalid email")
                    })
                )
        })
    }

    return Yup.object().shape({
        [item.id]: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().trim(),
                    surname: Yup.string().trim(),
                    email: Yup.string().trim().email("Invalid email")
                })
            )
    })
}