import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ total = 100, current = 0 }) => {
  const calculateWidth = (total, current) => {
    return `${(current * 100) / total}%`;
  };

  return (
    <div className="progress-bar__container">
      <div
        className="progress-bar__filler"
        style={{ width: calculateWidth(total, current) }}
      ></div>
    </div>
  );
};

export default ProgressBar;
