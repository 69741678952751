import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./ObserversList.scss";

import MyButton from "components/UI/Button/MyButton";
import ObserverItem from "../ObserverItem/ObserverItem";
import ObserversSlider from "components/Sliders/ObserversSlider";
import { dateTransform } from "helpers/dateTransform";

const ObserversList = ({ type, observers, colors, report, campaignStatus }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className='observers-list'>
      <div className='observers__type'>
        <div className='type-name'>{type}</div>
        <div
          className='invite__button'
          style={{ display: report ? "none" : "" }}
        >
          {observers?.length < 4 && campaignStatus !== "Finished" && (
            <MyButton
              className={observers.length !== 0 ? "invite required" : "invite"}
              onClick={() =>
                navigate(`/campaign/${id}/observers`, { state: type })
              }
            >
              +<span>Invite</span>
            </MyButton>
          )}
        </div>
      </div>
      <div className='observer-list__items'>
        {observers?.length > 0 &&
          observers.map((observer, index) => (
            <ObserverItem
              key={index}
              colors={colors}
              name={observer.name}
              surname={observer.surname}
              email={observer.email}
              date={dateTransform(observer.created_at)}
              isAnswered={observer.is_answered}
              invitationLink={observer.questionnaire_url}
            />
          ))}

        {observers?.length > 0 && (
          <ObserversSlider observers={observers} colors={colors} />
        )}

        {observers?.length === 0 && (
          <div className='no-observers'>
            <p>You have not invited anyone to this observer category yet.</p>
            {campaignStatus !== "Finished" && (
              <MyButton
                style={{ display: report ? "none" : "" }}
                className='invite mobile'
                onClick={() =>
                  navigate(`/campaign/${id}/observers`, { state: type })
                }
              >
                +<span>Invite</span>
              </MyButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ObserversList;
