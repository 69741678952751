import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import AppRouter from "./router/appRouter";
import { getAuthDataFromStorage } from 'services/auth';
import { useActions } from 'hooks/useActions';

function App() {

  const authData = getAuthDataFromStorage();
  const { updateUserData } = useActions();

  useEffect(() => {
    if (authData) {
      updateUserData(authData)
    }
  }, [authData])

  return (
    <BrowserRouter>
      <Header />
      <AppRouter />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
