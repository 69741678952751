import React, { useState, useEffect } from "react";
import './ObserverSurvey.scss';

import QuestionnaireForm from "components/Forms/QuestionnaireForm/QuestionnaireForm";
import Loader from "components/UI/Loader/Loader";
import { notification } from "helpers/notification";
import ResponseErrorHandler from "helpers/ResponseErrorHandler";
import { useFetching } from "hooks/useFetching";
import { useLocation } from "react-router-dom";
import { getObserverQuestions } from "services/API/getObserverQuestions";

const ObserverSurvey = () => {
  const [questions, setQuestions] = useState([]);

  const { state } = useLocation();

  const [fetchQuestions, isQuestionsLoading, questionsError] = useFetching(
    async (token, code) => {
      try {
        const { data } = await getObserverQuestions(token, code)
          .then((data) => data)
          .catch((error) => error.response);

        if (data?.status === "Success") {
          const newItem = {
            id: 'legend',
            type: 'informative_panel',
          };

          setQuestions([newItem, ...data.data]);
        }

        if (data?.status === "Error") {
          const err = new ResponseErrorHandler(data);
          notification("error", err.getValidationErrorMessages());
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  );

  useEffect(() => {
    if (state?.token && state?.code) {
      fetchQuestions(state.token, state.code);
    }
  }, []);

  if (isQuestionsLoading) {
    return <Loader />;
  }

  return (
    <div className="survey-wrapper">
      {questions.length > 0 && state?.token && state?.code && (
        <QuestionnaireForm
          questions={questions}
          observerCode={state?.code}
          observerToken={state?.token}
        />
      )}
    </div>
  );
};

export default ObserverSurvey;
