import React, { useEffect } from "react";
import "./Modal.scss";

const Modal = ({ children, active, setActive }) => {
  useEffect(() => {
    const body = document.querySelector("body");

    if (active) body.classList.add("lock");

    return () => body.classList.remove("lock");
  }, [active]);

  return (
    <div
      className={active ? "modal active" : "modal"}
      onClick={() => setActive(false)}
    >
      <div
        className={active ? "modal__content active" : "modal__content"}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
