import React from "react";
import "./InvitationDetails.scss";

// import MyButton from "components/UI/Button/MyButton";
import dueDateImage from "assets/images/due_date.svg";

const InvitationDetails = ({ name, dueDate }) => {
  return (
    <div className='invitation-details__wrapper'>
      <div className='invitation__title'>
        {name} invites you to submit a 360 assessment
      </div>
      <div className='invitation__due-date'>
        <img src={dueDateImage} className='due-date_image' alt='' />
        Due date: {dueDate}
      </div>
      {/* <div className="invitation__info">
        *You can gave assessment only during this session. If you want to break
        this session you will not be able to back here
      </div> */}
    </div>
  );
};

export default InvitationDetails;
