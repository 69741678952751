import axios from 'axios';
import { CAMPAIGNS } from 'constants/api';

export const getQuestions = async (token, id) => {
    const response = await axios.get(CAMPAIGNS.QUESTIONNAIRE(id),
        {
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Accept-Language': '*'
            }
        });

    return response;
}