import React from "react";
import "./FormTitleSection.scss";

import countIcon from "assets/images/count_icon.svg";
import FormTabs from "../FormTabs/FormTabs";

const FormTitleSection = ({
  tabs,
  activeTab,
  setActiveTab,
  validateForm,
  observers,
  totalCount = 0,
}) => {
  return (
    <div className='form-title__wrapper'>
      <div className='form-title__container'>
        <div className='title__content'>
          <div className='title'>Add Observers</div>
          <div className='description'>
            Select a tab to invite people who will submit an assessment for you.
          </div>
        </div>
        <div className='title__count'>
          <img className='total__icon' src={countIcon} alt='countIcon' />
          {`Total: ${totalCount}`}
        </div>
      </div>
      <FormTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        validateForm={validateForm}
        observers={observers}
      />
    </div>
  );
};

export default FormTitleSection;
