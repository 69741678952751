import React, { useState, useEffect } from "react";
import "./ObserverLandingPage.scss";

import { useFetching } from "hooks/useFetching";
import { checkObserver } from "services/API/checkObserver";
import Loader from "components/UI/Loader/Loader";
import { notification } from "helpers/notification";
import ResponseErrorHandler from "helpers/ResponseErrorHandler";
import LandingPageInfo from "./LandingPageInfo/LandingPageInfo";
import ObserverInfo from "./ObserverInfo/ObserverInfo";

const ObserverLandingPage = () => {
  const [observerData, setObserverData] = useState(null);
  const [error, setError] = useState(null);

  const queryParams = new URLSearchParams(window.location.search);
  const observerCode = queryParams.get("observer_code");

  const [fetchObserverCheck, isObserverCheckLoading, observerCheckError] =
    useFetching(async (code) => {
      try {
        const { data } = await checkObserver(code)
          .then((data) => data)
          .catch((error) => error.response);

        if (data?.status === "Success") {
          setObserverData(data.data);
        }

        if (data?.status === "Error") {
          if (data.error_code !== "access_denied") {
            const err = new ResponseErrorHandler(data);
            notification("error", err.getValidationErrorMessages());
          }
          setError(data.error_code);
        }
      } catch (error) {
        notification("error", error.message);
      }
    });
  
  useEffect(() => {
    if (observerCode) fetchObserverCheck(observerCode);
  }, []);

  return (
    <div className="landing-page__wrapper">
      {isObserverCheckLoading ? (
        <Loader />
      ) : (
        <>
          <div className="landing-page__section info">
            <LandingPageInfo />
          </div>
          <div className="landing-page__section observer">
            <ObserverInfo
              data={observerData}
              code={observerCode}
              error={error}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ObserverLandingPage;
