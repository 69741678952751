import { all, fork } from "redux-saga/effects";
import authSagas from "./auth";
import getCampaignsSaga from "./campaigns";
import getSingleCampaignSaga from "./singleCampaign";

const sagas = [
    authSagas,
    getCampaignsSaga,
    getSingleCampaignSaga
]

export function* rootSaga(services = {}) {
    yield all(sagas.map((saga) => fork(saga, services)));
}