import * as Yup from 'yup';

const passwordFormatWrong = 'Password: 8 chars min, upper, lower letter, digit and symbol: @$!%*#?&_';
const confirmationMissmatch = 'Passwords do not match'

export const registrationSchema = Yup.object({
    name: Yup.string()
        .required('This field is required').trim(),
    surname: Yup.string()
        .required('This field is required').trim(),
    email: Yup.string().email('Invalid email address')
        .required('This field is required'),
    password: Yup.string()
        .required('This field is required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
            passwordFormatWrong
    ),
    privacy: Yup.string().required('This field is required')
})

export const signInSchema = Yup.object({
    email: Yup.string().email('Invalid email address')
        .required('This field is required'),
    password: Yup.string()
        .required('This field is required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
            passwordFormatWrong
        ),
})

export const forgotPasswordSchema = Yup.object({
    email: Yup.string().email('Invalid email address')
        .required('This field is required')
})

export const createPasswordSchema = Yup.object({
    password: Yup.string()
        .required('Required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
            passwordFormatWrong
        ),
    password_confirmation: Yup.string().required('Required').when("password", {
        is: val => (val && val.length > 0),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            confirmationMissmatch
        )
    })
})