import { GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_ERROR } from "redux/constants";

const initialState = {
    campaigns: [],
    loading: false,
    error: null
}

export const campaignsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CAMPAIGNS:
            return { ...state, loading: true };
        case GET_CAMPAIGNS_SUCCESS:
            return { ...state, loading: false, campaigns: payload }
        case GET_CAMPAIGNS_ERROR:
            return { ...state, loading: false, error: payload }
        default:
            return state;
    }
}