import * as Yup from 'yup';

export const questionnaireFormValidationSchema = (item) => {

    if (item.type === "multiselect") {

        return Yup.object().shape({
            [item.id]: Yup.array().required("At list one option is required").min(1, "Please, choose at least one option")
        })
    }

    if (item.type === "informative_panel") {
        return Yup.object().shape({
            [item.id]: Yup.string()
        })
    }

    return Yup.object().shape({
        [item.id]: Yup.string().required('Field is required').trim()
    })
}