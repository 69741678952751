import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';

import './AuthForms.scss';

import RegistrationFormField from '../FormFields/RegistrationFormField/RegistrationFormField';
import MyButton from 'components/UI/Button/MyButton';
import { forgotPasswordSchema } from '../ValidationSchemas/AuthFormsValidationSchemas';
import axios from 'axios';
import { routePaths } from 'constants/routes';
import { API_BASE_URL } from 'constants/api';
import ResponseErrorHandler from 'helpers/ResponseErrorHandler';
import { notification } from 'helpers/notification';

const ForgotPasswordForm = () => {
  const { CHECK_EMAIL } = routePaths;

  const navigate = useNavigate();

  const submitHandler = async (values) => {
    const { data } = await axios({
      method: 'post',
      url: `${API_BASE_URL}request-password-change`,
      accept: 'application/json',
      data: values,
    })
      .then((data) => data)
      .catch((error) => error.response);

    if (data.status === 'Success') {
      navigate(CHECK_EMAIL);
    }

    if (data?.status === 'Error') {
      const err = new ResponseErrorHandler(data);

      notification('error', err.getValidationErrorMessages());
    }
  };

  return (
    <div className='auth-form__wrapper'>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordSchema}
        onSubmit={submitHandler}
      >
        {({ dirty, isValid }) => (
          <Form className='auth-form'>
            <Field
              name='email'
              type='email'
              label='Email'
              component={RegistrationFormField}
            />
            <MyButton
              type='submit'
              className='create-account'
              disabled={!dirty || !isValid}
            >
              Send me instructions
            </MyButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
