import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./Questionnaire.scss";
import QuestionnaireForm from "components/Forms/QuestionnaireForm/QuestionnaireForm";
import { useFetching } from "hooks/useFetching";
import { getQuestions } from "services/API/getQuestions";
import Loader from "components/UI/Loader/Loader";

const Questionnaire = () => {
  const [questions, setQuestions] = useState([]);
  const { userToken } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [fetchQuestions, isQuestionsLoading, questionsError] = useFetching(
    async (userToken, id) => {
      const response = await getQuestions(userToken, id);

      const newItem = {
        id: 'legend',
        type: 'informative_panel',
      };

      setQuestions([newItem, ...response.data.data]);
    }
  );

  useEffect(() => {
    fetchQuestions(userToken, id);
  }, [userToken, id]);

  if (isQuestionsLoading) {
    return <Loader />;
  }

  return (
    <div className="questionnaire-wrapper">
      {questions.length > 0 && <QuestionnaireForm questions={questions} />}
    </div>
  );
};

export default Questionnaire;
