import React from "react";
import DOMPurify from "dompurify";

const QuestionDetails = ({ question, description }) => {
    const sanitizedHtml = DOMPurify.sanitize(description);

    return (
        <>
            <div className="form-question">{question}</div>
            {description && (
                <div className="question-description" dangerouslySetInnerHTML={{__html: sanitizedHtml}}/>
            )}
        </>
    );
};

export default QuestionDetails;