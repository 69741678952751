import React from 'react';

const ObserversInfo = ({ language }) => {
  if (language === 'it') {
    return (
      <>
        <p>
          Ti verranno poste una serie di domande, alle quali dovrai rispondere
          nel modo più rapido e intuitivo possibile. Non soffermarti troppo a
          lungo a valutare le alternative. Se nessuna delle risposte presentate
          corrisponde pienamente al tuo pensiero, scegli quella che si avvicina
          di più.
        </p>
        <p className='middle'>
          Rispondi alle domande il più sinceramente possibile. Le risposte
          verranno rese anonime e il partecipante che stai valutando NON saprà
          chi le ha date. I punteggi che assegnerai verranno aggregati a quelli
          degli altri osservatori e pertanto NON saranno riconducibili a te.
        </p>
        <p>
          <b>Nota terminologica importante:</b> Il questionario è aggiornato
          alle più recenti evoluzioni della ricerca sullo sviluppo delle
          organizzazioni e delle carriere. Tiene dunque conto ad esempio del
          passaggio dall'impiego a tempo pieno al un tipo di carriera{' '}
          <b>"a portafoglio"</b>, nonché delle nuove professioni e modalità
          lavorative legate allo sviluppo tecnologico. La terminologia
          utilizzata nel questionario è in linea con tali sviluppi. <b>“Io”</b>{' '}
          si riferisce alla persona che valuterai, dal punto di vista
          professionale e privato. <b>“Contatti interpersonali”</b> sono le
          persone con cui lavora quotidianamente. Se è dipendente, si tratterà
          probabilmente del suo team; se è un freelance o un imprenditore, dei
          suoi colleghi. <b>“Comunità”</b>, se il partecipante che stai
          valutando è un lavoratore dipendente, può essere la sua
          organizzazione; se è un freelance o un imprenditore, potrebbero essere
          le diverse aziende di cui fa parte o i clienti con cui lavora.{' '}
          <b>“Ecosistema”</b> può essere il settore in cui opera lo l'azienda
          del partecipante o gli ambiti in cui lavora, al di fuori dei confini
          dell'organizzazione.
        </p>
      </>
    );
  }

  return (
    <>
      <p>
        You will be asked a set of questions. Please answer as intuitively and
        quickly as possible. Don't overthink the choices. If none of the choices
        apply fully, select the closest that applies.
      </p>
      <p className='middle'>
        Please answer to the questions as truthfully as possible. Your answers
        will be anonymised and the participant you are assessing will NOT know
        who the answers are from. Scores you are giving will be aggregated with
        the scores of other observers and thus NOT linkable to you.
      </p>
      <p>
        <b>Important notice on terminology:</b> This questionnarie considers the
        latest developments in the field of careers and organusational
        developments. As such is taking into account (e.g. the shifts into
        portfolio careers versus full-time employment or new technology related
        professions and ways of working). The terminology used in this
        questionnaire is adopted to these new organisational developments.
        <b>"Self"</b> refers to the person you will be evaluating (both
        professionally and privately). <b>"Interpersonal Connections"</b> are
        the people the participant you evaluated is working with on a daily
        basis. If the participant is employed this will likely be their team, if
        you the participant is a freelancer/entrepreneur this can their
        colleagues. <b>"Community"</b> if the participant you are evaluating is
        employed this can be their organization, if the participant is a
        freelancer/entrepreneur it might be the different companies that they
        are part of or clients they work with. <b>"Ecosystem"</b> can be the
        industry your company is part of or areas they are working in, these
        areas go beyond just organisations.
      </p>
    </>
  );
};

export default ObserversInfo;
