import React from "react";
import TitleSection from "components/TitleSection/TitleSection";
import EmailImage from "assets/images/email_letter_image.svg";

import "./CheckEmail.scss";

const CheckEmail = () => {
  return (
    <section className="check-email__section">
      <TitleSection
        title="Please Check Your Email"
        description="We’ve sent you instructions"
      />
      <div className="email__image">
        <img src={EmailImage} alt="Email" />
      </div>
    </section>
  );
};

export default CheckEmail;
