import React from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';

import './AuthForms.scss';

import RegistrationFormField from '../FormFields/RegistrationFormField/RegistrationFormField';
import MyButton from 'components/UI/Button/MyButton';
import { createPasswordSchema } from '../ValidationSchemas/AuthFormsValidationSchemas';
import { API_BASE_URL } from 'constants/api';
import ResponseErrorHandler from 'helpers/ResponseErrorHandler';
import { notification } from 'helpers/notification';
import transformEmailFromSearchParams from 'helpers/transformEmailFromSearchParams';
import saveUserCredentials from 'helpers/SaveUserCredentials';
import { useActions } from 'hooks/useActions';

const CreatePasswordForm = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const email = transformEmailFromSearchParams(queryParams.get('email'));

  const { loginSuccess } = useActions();

  const submitHandler = async (values) => {
    values.email = email;
    values.token = token;

    const { data } = await axios({
      method: 'post',
      url: `${API_BASE_URL}change-password`,
      accept: 'application/json',
      data: values,
    })
      .then((data) => data)
      .catch((error) => error.response);

    if (data.status === 'Success') {
      const authData = data.data;

      loginSuccess(authData);
      saveUserCredentials(authData);
    }

    if (data?.status === 'Error') {
      const err = new ResponseErrorHandler(data);

      notification('error', err.getValidationErrorMessages());
    }
  };

  return (
    <div className='auth-form__wrapper'>
      <Formik
        initialValues={{
          password: '',
          password_confirmation: '',
        }}
        validationSchema={createPasswordSchema}
        onSubmit={submitHandler}
      >
        {({ dirty, isValid }) => (
          <Form className='auth-form'>
            <Field
              name='password'
              type='password'
              label='New password'
              component={RegistrationFormField}
            />
            <Field
              name='password_confirmation'
              type='password'
              label='Repeat new password'
              component={RegistrationFormField}
            />
            <MyButton
              type='submit'
              className='create-account'
              disabled={!dirty || !isValid}
            >
              Create new password
            </MyButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreatePasswordForm;
