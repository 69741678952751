import { GET_SINGLE_CAMPAIGN, GET_SINGLE_CAMPAIGN_SUCCESS, GET_SINGLE_CAMPAIGN_ERROR, CLEAR_SINGLE_CAMPAIGN_DATA } from "redux/constants";

const initialState = {
    campaign: null,
    loading: false,
    error: null
}

export const singleCampaignReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_SINGLE_CAMPAIGN:
            return { ...state, loading: true };
        case GET_SINGLE_CAMPAIGN_SUCCESS:
            return { ...state, loading: false, campaign: payload }
        case GET_SINGLE_CAMPAIGN_ERROR:
            return { ...state, loading: false, error: payload }
        case CLEAR_SINGLE_CAMPAIGN_DATA:
            return { ...state, ...initialState }
        default:
            return state;
    }
}