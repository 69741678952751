import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./LanguageSelection.scss";

import closeIcon from "assets/images/close_icon.svg";
import { languageSelection } from "helpers/LanguageSelection";
import MyButton from "components/UI/Button/MyButton";
import ResponseErrorHandler from "helpers/ResponseErrorHandler";
import { SELECT_LANGUAGE } from "constants/api";

const LanguageSelection = ({ active, setActive, token, navLink, navState }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const navigate = useNavigate();
  
  useEffect(() => {
    if (!active) {
      setSelectedLanguage('')
    }
  }, [active]);

  const selectLanguageHandler = (language, token, navLink, navState) => {
    axios({
      method: "post",
      url: SELECT_LANGUAGE,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      accept: "application/json",
      data: {
        language: language,
      },
    })
      .then((response) => {
        if (response.data.status === "Success") {
          navigate(navLink, {state: navState ? {...navState, language} : {language}});
        }
      })
      .catch((error) => {
        const responseError = new ResponseErrorHandler(error.response.data);
        console.log(responseError.getValidationErrorMessages());
      });
  };
  
  return (
    <div className="language-selection__wrapper">
      <img
        className="close__btn"
        src={closeIcon}
        alt="close-icon"
        onClick={() => setActive(false)}
      />
      <div className="language-selection__content">
        <div className="content__title">
          Please Choose a Language for Questioner and Report
        </div>
        <div className="content__description">
          You can choose a language just one time here. You will not be able to
          change it later
        </div>
        <div className="selection__buttons">
          {languageSelection.map((language) => {
            return (
              <MyButton
                key={language.id}
                className={
                  selectedLanguage === language.value
                    ? "language active"
                    : "language"
                }
                onClick={() => setSelectedLanguage(language.value)}
              >
                <img className="language__icon" src={language.icon} alt="" />
                {language.label}
              </MyButton>
            );
          })}
        </div>
        <div className="continue__button">
          <MyButton
            disabled={selectedLanguage === ""}
            className="continue"
            onClick={() => selectLanguageHandler(selectedLanguage, token, navLink, navState)}
          >
            Continue
          </MyButton>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelection;
