import axios from 'axios';
import { fork, put, call, takeLatest } from "redux-saga/effects";

import { CAMPAIGNS } from 'constants/api';
import { getCampaignsError, getCampaignsSuccess } from 'redux/actions/campaigns';
import { GET_CAMPAIGNS } from "redux/constants";
import ResponseErrorHandler from 'helpers/ResponseErrorHandler';


function* watchGetCampaigns() {
    yield takeLatest(GET_CAMPAIGNS, handleGetCampaigns)
}

function* handleGetCampaigns({ payload: token }) {
    try {
        const { data } = yield call(() => axios.get(CAMPAIGNS.LIST,
            { 'headers': { 'Authorization': `Bearer ${token}` } })
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            yield put(getCampaignsSuccess(data.data))
        }

        if (data?.status === 'Error') {
            const err = new ResponseErrorHandler(data);

            yield put(getCampaignsError(err.getValidationErrorMessages()))
        }

    } catch (error) {
        yield put(getCampaignsError(error.message))
    }
}

export default function* getCampaignsSaga() {
    yield fork(watchGetCampaigns)
}
