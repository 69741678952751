export const rangeOptionsColors = [
    {
        color: 'rgba(178, 34, 34, 1)',
        backgroundColor: 'rgba(178, 34, 34, 0.2)'
    },
    {
        color: 'rgba(255, 127, 80, 1)',
        backgroundColor: 'rgba(255, 127, 80, 0.2)'
    },
    {
        color: 'rgba(255, 218, 185, 1)',
        backgroundColor: 'rgba(255, 218, 185, 0.2)'
    },
    {
        color: 'rgba(255, 255, 224, 1)',
        backgroundColor: 'rgba(255, 255, 224, 0.2)'
    },
    {
        color: 'rgba(152, 251, 152, 1)',
        backgroundColor: 'rgba(152, 251, 152, 0.2)'
    },
    {
        color: 'rgba(32, 178, 170, 1)',
        backgroundColor: 'rgba(32, 178, 170, 0.2)'
    },
    {
        color: 'rgba(34, 139, 34, 1)',
        backgroundColor: 'rgba(34, 139, 34, 0.2)'
    },
    {
        color: 'rgba(60, 168, 97, 1)',
        backgroundColor: 'rgba(60, 168, 97, 0.2)'
    },
    // TODO: need to expand the list of color
    {
        color: 'rgba(91, 203, 201, 1)',
        backgroundColor: 'rgba(91, 203, 201, 0.2)'
    },
    {
        color: 'rgba(94, 211, 242, 1)',
        backgroundColor: 'rgba(94, 211, 242, 0.2)'
    },
    {
        color: 'rgba(46, 121, 218, 1)',
        backgroundColor: 'rgba(46, 121, 218, 0.2)'
    },
    {
        color: 'rgba(236, 78, 124, 1)',
        backgroundColor: 'rgba(236, 78, 124, 0.2)'
    },
    {
        color: 'rgba(233, 51, 63, 1)',
        backgroundColor: 'rgba(233, 51, 63, 0.2)'
    },
    {
        color: 'rgba(132, 76, 187, 1)',
        backgroundColor: 'rgba(132, 76, 187, 0.2)'
    },
    {
        color: 'rgba(224, 112, 77, 1)',
        backgroundColor: 'rgba(224, 112, 77, 0.2)'
    },
    {
        color: 'rgba(60, 168, 97, 1)',
        backgroundColor: 'rgba(60, 168, 97, 0.2)'
    }
]