import React from "react";
import "./Header.scss";
import { useLocation } from "react-router-dom";
import Logout from "components/Logout/Logout";
import logo from "assets/images/logo.svg";
import { useSelector } from "react-redux";

const Header = () => {
  const { pathname } = useLocation();

  const { campaign, loading } = useSelector((state) => state.singleCampaign);
  const { userToken } = useSelector((state) => state.auth);

  return (
    <header
      className={
        pathname.includes("/registration") ||
        pathname.includes("/observer/questionnaire")
          ? "header header__hidden"
          : "header"
      }
    >
      <img className="main-logo" src={logo} alt="logo" />

      {pathname.includes("campaign/") && !loading && campaign?.school_logo && (
        <div className="school-logo">
          <img src={campaign.school_logo} alt="school_logo" />
        </div>
      )}

      {userToken && <Logout />}
    </header>
  );
};

export default Header;
