import React, { useEffect } from "react";
import "./Observers.scss";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useActions } from "hooks/useActions";
import Loader from "components/UI/Loader/Loader";
import AddObserversForm from "components/Forms/AddObserversForm/AddObserversForm";
import { notification } from 'helpers/notification';

const Observers = () => {
  const { id } = useParams();
  const { userToken } = useSelector((state) => state.auth);
  const { campaign, loading } = useSelector((state) => state.singleCampaign);

  const navigate = useNavigate();

  const { getSingleCampaign } = useActions();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!campaign) {
      getSingleCampaign({ userToken, id });
    }
    if (campaign?.status === 'Finished') {
      navigate(`/campaign/${id}`);
      notification(
        'info',
        'You are not able to add observers.'
      );
    }
  }, [userToken, id, campaign]);
  
  if (loading) {
    return <Loader />;
  }

  return (
    <section className="add-observers__section">
      {campaign && <AddObserversForm campaign={campaign} />}
    </section>
  );
};

export default Observers;
