import React from "react";
import "./SignIn.scss";

import AuthLinks from "components/AuthLinks/AuthLinks";
import SignInForm from "components/Forms/AuthForms/SignInForm";
import TitleSection from "components/TitleSection/TitleSection";

const SignIn = () => {

  return (
    <section className="sign-in__section">
      <TitleSection
        title="Sign In"
        description="Please fill out  all information below to enter your account"
      />
      <SignInForm />
      <AuthLinks
        state={{ previousPage: true }}
      />
    </section>
  );
};

export default SignIn;
