import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_ERROR,
    UPDATE_USER_DATA_FROM_LOCALSTORAGE,
    LOGOUT
} from "redux/constants";

// login flow
export function loginRequest(requestData) {
    return {
        type: LOGIN_REQUEST,
        payload: requestData
    }
}

export function loginSuccess(successData) {
    return {
        type: LOGIN_SUCCESS,
        payload: successData
    }
}

export function loginError(errorData) {
    return {
        type: LOGIN_ERROR,
        payload: errorData
    }
}

// registration flow
export function registrationRequest(requestData) {
    return {
        type: REGISTRATION_REQUEST,
        payload: requestData
    }
}

export function registrationSuccess(successData) {
    return {
        type: REGISTRATION_SUCCESS,
        payload: successData
    }
}

export function registrationError(errorData) {
    return {
        type: REGISTRATION_ERROR,
        payload: errorData
    }
}

// updating auth data
export function updateUserData(userData) {
    return {
        type: UPDATE_USER_DATA_FROM_LOCALSTORAGE,
        payload: userData,
    };
}

// logout
export function logout() {
    return {
        type: LOGOUT
    };
}