import React from "react";
import { ErrorMessage } from "formik";
import Select from "react-select";
import { questionnaireSelect as selectStyles } from 'helpers/customSelectStyles';
import QuestionDetails from "components/Forms/QuestionnaireForm/FormSteps/QuestionDetails";

const Dropdown = ({ name, description, field, question, options, values, form }) => {
  const fieldName = name || field.name;

  const { setFieldValue } = form;

  const onChangeSelect = (optionValue) => {
    setFieldValue(field.name, optionValue.id);
  };

  return (
      <>
          <QuestionDetails question={question} description={description} />
          <div className="select__container">
              <Select
                  className="dropdown-select"
                  isSearchable={false}
                  styles={selectStyles}
                  name={fieldName}
                  getOptionLabel={(option) => option.option}
                  getOptionValue={(option) => option.id}
                  options={options}
                  placeholder="Choose your option"
                  defaultValue={
                      field.value !== ""
                          ? options.find((option) => option.id === field.value)
                          : null
                  }
                  onChange={(optionValue) => onChangeSelect(optionValue)}
              />
              <ErrorMessage
                  name={fieldName}
                  render={(msg) => <p className="error-message">{msg}</p>}
              />
          </div>
      </>
  );
};

export default Dropdown;
