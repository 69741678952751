import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './StepItems.scss';

import CampaignStepItem from '../CampaignStepItem/CampaignStepItem';
import completedImage from 'assets/images/completed_sign.svg';
import LanguageSelection from 'components/LanguageSelection/LanguageSelection';
import Modal from 'components/UI/Modal/Modal';
import AddObserversModalContent from 'components/AddObserversModalContent/AddObserversModalContent';
import FinishedCampaignModalContent from 'components/FinishedCampaignModalContent/FinishedCampaignModalContent';

const StepItems = ({
  completed_date,
  self_assessment,
  observers,
  observerTypes,
  campaignStatus,
}) => {
  const [languagesModalActive, setLanguagesModalActive] = useState(false);
  const [addObserversModalActive, setAddObserversModalActive] = useState(false);
  const { userToken } = useSelector((state) => state.auth);
  const { campaign } = useSelector((state) => state.singleCampaign);

  if (observers.length > 0 && self_assessment) {
    return (
      <div className='complited-item__wrapper'>
        <img
          className='complited-item__image'
          src={completedImage}
          alt='completedImage'
        />
        <div className='complited-item__content'>
          <div className='content__title'>Self Assessment</div>
          <div className='content__date'>Completed date: {completed_date}</div>
        </div>
        <div className='complited-item__status'>Completed</div>
      </div>
    );
  }

  return (
    <div className='step-items__section'>
      <CampaignStepItem
        step='1'
        active={true}
        title='Self Assessment'
        description='Please complete self assessment'
        completed={self_assessment}
        completed_date={completed_date}
        callback={setLanguagesModalActive}
        campaignStatus={campaignStatus}
      />
      <CampaignStepItem
        step='2'
        title='Add People'
        active={self_assessment}
        description='Add people to gave you an assessment'
        callback={setAddObserversModalActive}
        campaignStatus={campaignStatus}
        self_assessment={self_assessment}
      />
      <Modal active={languagesModalActive} setActive={setLanguagesModalActive}>
        <LanguageSelection
          active={languagesModalActive}
          setActive={setLanguagesModalActive}
          token={userToken}
          navLink={`/campaign/${campaign.id}/self-assessment`}
          navState={null}
        />
      </Modal>
      <Modal
        active={addObserversModalActive}
        setActive={setAddObserversModalActive}
      >
        {campaignStatus !== 'Finished' ? (
          <AddObserversModalContent
            setActive={setAddObserversModalActive}
            requiredTypes={observerTypes?.filter((type) => type.required)}
          />
        ) : (
          <FinishedCampaignModalContent
            setActive={setAddObserversModalActive}
          />
        )}
      </Modal>
    </div>
  );
};

export default StepItems;
