import React, { useEffect } from "react";
import TitleSection from "components/TitleSection/TitleSection";
import "./ForgotPassword.scss";
import ForgotPasswordForm from "components/Forms/AuthForms/ForgotPasswordForm";

const ForgotPassword = () => {
  useEffect(() => {
    localStorage.removeItem("login");
  }, []);

  return (
    <section className="forgot-password__section">
      <TitleSection
        title="Forgot Password?"
        description="Please fill the form below to get instructions how to create new password"
      />
      <ForgotPasswordForm />
    </section>
  );
};

export default ForgotPassword;
