import React from 'react';
import './ReportSection.scss';

import reportImage from 'assets/images/report_image.svg';
import MyButton from 'components/UI/Button/MyButton';

const ReportSection = ({ url }) => {

  const downloadReportFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    // link.download = `_report.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className='report-section'>
      <div className='section__content'>
        <img src={reportImage} alt='reportImage' />
        <div className='report-title'>Your 360 Assessment Report is Ready</div>
        <div className='report-description'>
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit. Exercitation
          veniam consequat sunt nostrud amet.
        </div>
        <MyButton onClick={() => downloadReportFile(url)} className='report'>
          Download
        </MyButton>
      </div>
    </section>
  );
};

export default ReportSection;
