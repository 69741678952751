import React from 'react';

import './FinishedCampaignModalContent.scss';

import closeIcon from 'assets/images/close_icon.svg';

const FinishedCampaignModalContent = ({setActive}) => {
  return (
    <div className='finished-campaign-modal__wrapper'>
      <img
        className='close__btn'
        src={closeIcon}
        alt='close-icon'
        onClick={() => setActive(false)}
      />
      <div className='finished-campaign-modal__content'>
        The campaign is finished. You are not able to add observers.
      </div>
    </div>
  );
};

export default FinishedCampaignModalContent;
