import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_ERROR,
    UPDATE_USER_DATA_FROM_LOCALSTORAGE,
    LOGOUT
} from "redux/constants";

const initialState = {
    userName: '',
    userSurname: '',
    userEmail: '',
    userToken: '',
    requestSending: false,
    loginError: null,
    registrationError: null
}

export const authReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // login flow
        case LOGIN_REQUEST:
            return { ...state, requestSending: true };

        case LOGIN_SUCCESS:
            return {
                ...state,
                userName: payload.name,
                userSurname: payload.surname,
                userEmail: payload.email,
                userToken: payload.token,
                requestSending: false
            };
        case LOGIN_ERROR:
            return { ...state, requestSending: false, loginError: payload };

        // registration flow
        case REGISTRATION_REQUEST:
            return { ...state, requestSending: true };

        case REGISTRATION_SUCCESS:
            return {
                ...state,
                userName: payload.name,
                userSurname: payload.surname,
                userEmail: payload.email,
                userToken: payload.token,
                requestSending: false
            };

        case REGISTRATION_ERROR:
            return { ...state, requestSending: false, registrationError: payload };

        // data updating
        case UPDATE_USER_DATA_FROM_LOCALSTORAGE:
            return {
                ...state,
                userName: payload.name,
                userSurname: payload.surname,
                userEmail: payload.email,
                userToken: payload.token
            };

        // logout
        case LOGOUT:
            return {
                ...state,
                ...initialState
            }

        default:
            return state;
    }
}