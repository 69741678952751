import { GET_SINGLE_CAMPAIGN, GET_SINGLE_CAMPAIGN_SUCCESS, GET_SINGLE_CAMPAIGN_ERROR, CLEAR_SINGLE_CAMPAIGN_DATA } from "redux/constants"

export function getSingleCampaign(payload) {
    return {
        type: GET_SINGLE_CAMPAIGN,
        payload: payload
    }
}

export function getSingleCampaignSuccess(campaigns) {
    return {
        type: GET_SINGLE_CAMPAIGN_SUCCESS,
        payload: campaigns
    }
}

export function getSingleCampaignError(error) {
    return {
        type: GET_SINGLE_CAMPAIGN_ERROR,
        payload: error
    }
}

export function clearSingleCampaignData() {
    return {
        type: CLEAR_SINGLE_CAMPAIGN_DATA
    }
}