import React from "react";
import "./AddObserversFormFields.scss";

const AddObserversFormFields = ({
  type,
  field,
  form,
  label,
  observerType,
  fieldName,
  index,
}) => {
  const { touched, errors, values } = form;

  return (
    <>
      <div
        className={
          touched[observerType]?.[index]?.[fieldName] &&
          errors[observerType]?.[index]?.[fieldName]
            ? "input__wrapper input__wrapper--error"
            : "input__wrapper"
        }
      >
        <input type={type} className="registration__input" {...field} />
        <label
          className={
            values[observerType][index][fieldName] === ""
              ? "input-label"
              : "input-label input-label--active"
          }
        >
          {label}
        </label>
      </div>
      {touched[observerType]?.[index]?.[fieldName] &&
        errors[observerType]?.[index]?.[fieldName] && (
          <div className="field__error">
            {errors[observerType]?.[index]?.[fieldName]}
          </div>
        )}
    </>
  );
};

export default AddObserversFormFields;
