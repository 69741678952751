import axios from 'axios';
import { OBSERVER_QUESTIONNAIRE } from 'constants/api';

export const getObserverQuestions = async (token, code) => {
    const response = await axios.get(OBSERVER_QUESTIONNAIRE(code),
        {
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Accept-Language': '*'
            }
        });

    return response;
}