import React from "react";
import { useFormikContext } from "formik";
import "./FormTabs.scss";
import { notification } from "helpers/notification";

const FormTabs = ({
  tabs,
  activeTab,
  setActiveTab,
  validateForm,
  observers,
}) => {
  const { submitForm, values } = useFormikContext();

  const changeTabHandler = (item) => {
    if (
      observers.filter((observer) => observer.observer_type_id === activeTab.id)
        .length < 1 &&
      !activeTab.required &&
      (values[activeTab.id][0].name !== "" ||
        values[activeTab.id][0].surname !== "" ||
        values[activeTab.id][0].email !== "") &&
      (values[activeTab.id][1].name === "" ||
        values[activeTab.id][1].surname === "" ||
        values[activeTab.id][1].email === "")
    ) {
      notification(
        "error",
        `In case you want to add ${activeTab.name} you have to add at least 2 and fill in all fields`
      );
    } else {
      validateForm().then((errors) => {
        if (errors[activeTab.id]) {
          submitForm();
        } else {
          setActiveTab(item);
        }
      });
    }
  };

  return (
    <div className="form-tabs__wrapper">
      <div className="tabs__container">
        {tabs?.map((item) => (
          <div
            key={item.id}
            className={
              activeTab.name === item.name ? "tab__item active" : "tab__item"
            }
            onClick={() => changeTabHandler(item)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormTabs;
