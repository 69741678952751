import React from "react";
import { Link } from "react-router-dom";
import "./MyLink.scss";

const MyLink = ({ children, className, to = "/", state, ...props }) => {
  return (
    <Link
      className={className ? `MyLink MyLink__${className}` : "MyLink"}
      to={to}
      state={state}
      {...props}
    >
      {children}
    </Link>
  );
};

export default MyLink;
