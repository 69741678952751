import React from 'react';
import MyLink from 'components/UI/Link/MyLink';
import { routePaths } from 'constants/routes';

import './AuthLinks.scss';

const AuthLinks = ({
  to,
  text,
  linkLabel,
  state,
  showForgorPassword = true,
}) => {
  const { FORGOT_PASSWORD } = routePaths;

  return (
    <div className='registration__links'>
      {showForgorPassword && (
        <MyLink to={FORGOT_PASSWORD} state={state} className='registration'>
          Forgot password
        </MyLink>
      )}
      {text && linkLabel && (
        <div className='sign-in'>
          {text}
          <MyLink to={to} state={state} className='registration'>
            {linkLabel}
          </MyLink>
        </div>
      )}
    </div>
  );
};

export default AuthLinks;
