export const rangeFiveOptionsColors = [
    {
        color: 'rgba(178, 34, 34, 1)',
        backgroundColor: 'rgba(178, 34, 34, 0.2)'
    },
    {
        color: 'rgba(255, 218, 185, 1)',
        backgroundColor: 'rgba(255, 218, 185, 0.2)'
    },
    {
        color: 'rgba(255, 255, 224, 1)',
        backgroundColor: 'rgba(255, 255, 224, 0.2)'
    },
    {
        color: 'rgba(152, 251, 152, 1)',
        backgroundColor: 'rgba(152, 251, 152, 0.2)'
    },
    {
        color: 'rgba(34, 139, 34, 1)',
        backgroundColor: 'rgba(34, 139, 34, 0.2)'
    }
]