import React from 'react';
import './CampaignStepItem.scss';

import MyButton from 'components/UI/Button/MyButton';
import arrow from 'assets/images/proceed_arrow.svg';
import lock from 'assets/images/lock.svg';
import completedImage from 'assets/images/completed_sign.svg';

const CampaignStepItem = ({
  step,
  description,
  title,
  completed_date = false,
  active = false,
  completed = false,
  callback,
  campaignStatus,
  self_assessment
}) => {
  const proceedButton = (status) => {
    if (status !== 'Finished' || self_assessment) {
      return (
        <MyButton onClick={() => callback(true)} className='proceed'>
          Proceed <img src={arrow} alt='arrow' />
        </MyButton>
      );
    }
    return null;
  };

  return (
    <div
      className={
        completed
          ? 'campaign-step__item campaign-step__item--completed'
          : active
          ? 'campaign-step__item campaign-step__item--active'
          : 'campaign-step__item'
      }
    >
      <div className='item__step'>Step {step}</div>
      <div className='item__info'>
        <div className='info__description'>{description}</div>
        <div className='info__title'>
          {completed && <img src={completedImage} alt='completed' />} {title}
        </div>
      </div>
      <div className='item__button'>
        {completed_date ? (
          <div className='completed-date'>Completed date: {completed_date}</div>
        ) : active ? (
          proceedButton(campaignStatus)
        ) : (
          <div className='lock__message'>
            <img src={lock} alt='lock' /> Complete first step please
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignStepItem;
