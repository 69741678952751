import React from "react";
import "./StepsContent.scss";
import { Field, FieldArray, useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import AddObserversFormFields from "components/Forms/FormFields/AddObserversFormFields/AddObserversFormFields";
import MyButton from "components/UI/Button/MyButton";
import nextBtn from "assets/images/next_btn.svg";
import ObserversInfo from "./ObserversInfo/ObserversInfo";
import ExistObserversList from "./ExistObserversList/ExistObserversList";

const StepContent = ({ values, name, type, observers }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { errors } = useFormikContext();

  const fieldArrayErrors = (errors) =>
    typeof errors[type.id] === "string" ? <div>{errors[type.id]}</div> : null;

  return (
    <>
      <ObserversInfo type={type} observers={observers} />
      {observers.length > 0 && <ExistObserversList observers={observers} />}
      <FieldArray name={name}>
        {({ insert, remove, push }) => (
          <div className="form-step__wrapper">
            {values[name].length > 0 &&
              values[name].map((observer, index) => (
                <div key={index} className="observer-fields__wrapper">
                  <div className="observer__number">Observer # {index + 1}</div>
                  <div className="fields__container">
                    <div className="field__item">
                      <Field
                        name={`${name}.${index}.name`}
                        type="text"
                        label="First name"
                        component={AddObserversFormFields}
                        observerType={name}
                        fieldName="name"
                        index={index}
                      />
                    </div>
                    <div className="field__item">
                      <Field
                        name={`${name}.${index}.surname`}
                        type="text"
                        label="Last name"
                        component={AddObserversFormFields}
                        observerType={name}
                        fieldName="surname"
                        index={index}
                      />
                    </div>
                    <div className="field__item">
                      <Field
                        name={`${name}.${index}.email`}
                        type="email"
                        label="Email"
                        component={AddObserversFormFields}
                        observerType={name}
                        fieldName="email"
                        index={index}
                      />
                    </div>
                  </div>
                </div>
              ))}
            {fieldArrayErrors(errors)}
            <div
              className="form__buttons"
              style={{
                justifyContent:
                  values[name].length + observers?.length < 4 ? "" : "flex-end",
              }}
            >
              {values[name].length + observers.length < 4 && (
                <MyButton
                  className="observer"
                  type="button"
                  onClick={() =>
                    push({
                      name: "",
                      surname: "",
                      email: "",
                      observer_type_id: type.id,
                    })
                  }
                >
                  + Add More
                </MyButton>
              )}
              <div className="send-and-cancel">
                <MyButton
                  className="cancel"
                  onClick={() => navigate(`/campaign/${id}`)}
                >
                  Cancel
                </MyButton>
                <MyButton type="submit" className="questionnaire-submit">
                  Send <img src={nextBtn} alt="nextBtn" />
                </MyButton>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default StepContent;
