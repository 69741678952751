import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RegistrationForm from 'components/Forms/AuthForms/RegistrationForm';
import './Registration.scss';
import AuthLinks from 'components/AuthLinks/AuthLinks';
import logo from 'assets/images/logo_white.svg';
import { routePaths } from 'constants/routes';
import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useActions';

import backgroundImage from 'assets/images/Eclipse.png';

const Registration = () => {
  const { SIGN_IN, HOME } = routePaths;

  const { code } = useParams();

  const navigate = useNavigate();

  const { userToken, userName, userSurname } = useSelector(
    (state) => state.auth
  );

  const { registrationRequest } = useActions();

  useEffect(() => {
    if (code) {
      localStorage.setItem('campaign_code', code);
    }
  }, [code]);

  useEffect(() => {
    if (userToken) {
      registrationRequest(
        {
          token: userToken,
          campaign_code: code,
        },
        navigate(HOME)
      );
    }
  }, [userToken]);

  return (
    <section className='registration-section'>
      <div className='registration-title__section'>
        <div className='title__container'>
          <div className='container__logo'>
            <img src={logo} alt='logo' />
          </div>
          <h1 className='container__title'>
            Welcome to the META 360 Assessment!
          </h1>
          <div className='container__description'>
            <p>
              If you have been approved for a META 360 account, enter your
              information on the right side of this page, select a password,
              then click "Create Account".
            </p>
            <p>If you already have an account, click "Sign In".</p>
          </div>
        </div>
        <img className='decorate-image' src={backgroundImage} alt='' />
      </div>
      <div className='form__section'>
        <div className='form__container'>
          <div className='form__title'>Sign Up</div>
          <RegistrationForm campaignCode={code} />
          <AuthLinks
            to={SIGN_IN}
            state={{ previousPage: true }}
            text='Already have an account?'
            linkLabel='Sign In'
            showForgorPassword={false}
          />
        </div>
      </div>
    </section>
  );
};

export default Registration;
