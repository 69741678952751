import React, { useEffect } from "react";
import CreatePasswordForm from "components/Forms/AuthForms/CreatePasswordForm";
import TitleSection from "components/TitleSection/TitleSection";
import "./CreatePassword.scss";

const CreatePassword = () => {
  useEffect(() => {
    localStorage.removeItem("login");
  }, []);

  return (
    <section className="create-password__section">
      <TitleSection
        title="Create New Password"
        description="Password should consist of 8 symbols and include a minimum of one capital letter, one number and one special symbol"
      />
      <CreatePasswordForm />
    </section>
  );
};

export default CreatePassword;
