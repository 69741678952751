import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Logout.scss';

import MyButton from 'components/UI/Button/MyButton';
import logoutImage from 'assets/images/Logout.svg';
import { useActions } from 'hooks/useActions';
import { clearLocalStorage } from 'services/auth';
import { routePaths } from 'constants/routes';

const Logout = () => {
  const { userName, userSurname } = useSelector((state) => state.auth);
  const { SIGN_IN } = routePaths;

  const navigate = useNavigate();

  const { logout } = useActions();

  const { pathname } = useLocation();

  const logoutHandler = () => {
    clearLocalStorage();
    logout();
    navigate(SIGN_IN);
  };

  return (
    <div className='logout__wrapper'>
      <div
        className={
          pathname.includes('campaign/') ? 'user-info campaign' : 'user-info'
        }
      >
        <div className='user-firstname'>{userName}</div>
        <div className='user-lastname'>{userSurname}</div>
      </div>
      <MyButton className='logout' onClick={() => logoutHandler()}>
        <img src={logoutImage} alt='' />
      </MyButton>
    </div>
  );
};

export default Logout;
