import React, { useState } from "react";
import "./LandingPageInfo.scss";
import logo from "assets/images/logo_white.svg";

const LandingPageInfo = () => {
  // const [isFullDescriptionShown, setIsFullDescriptionShown] = useState(false);

  return (
    <div className='info__container'>
      <div className='info__logo'>
        <img src={logo} alt='logo' />
      </div>
      <div className='info__title'>Welcome to the META 360 Assessment!</div>
      {/* <div
        className={`info__description ${
          isFullDescriptionShown ? 'full-content' : ''
        }`}
      >
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
        sint. Velit officia consequat duis enim velit mollit. Exercitation
        veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
        ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis
        enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </div>
      <div
        className='show-more__btn'
        onClick={() => setIsFullDescriptionShown(!isFullDescriptionShown)}
      >
        {isFullDescriptionShown ? 'Show less' : 'Show more'}
      </div> */}
    </div>
  );
};

export default LandingPageInfo;
