import React from "react";
import { ErrorMessage } from "formik";
import Radio from "components/UI/Radio/Radio";
import { rangeFiveOptionsColors } from "helpers/rangeFiveOptionsColors";
import QuestionDetails from "components/Forms/QuestionnaireForm/FormSteps/QuestionDetails";

const Range = ({
  question,
  name,
  description,
  field,
  form,
  values,
  count,
  max_scale_text,
  min_scale_text,
}) => {
  const fieldName = name || field.name;

  const { setFieldValue } = form;

  const setSelected = (value) => {
    setFieldValue(fieldName, value);
  };

  return (
      <>
        <QuestionDetails question={question} description={description} />
        <div className="range__container container">
          <div className="criteria min mobile">{min_scale_text}</div>
          <div className="radio__list">
            {Array.from(Array(count)).map((x, index) => (
                <Radio
                    key={index}
                    value={index}
                    name={fieldName}
                    selected={values[fieldName]}
                    onChange={setSelected}
                    showValue={true}
                    color={rangeFiveOptionsColors[index].color}
                    backgroundColor={rangeFiveOptionsColors[index].backgroundColor}
                />
            ))}
          </div>
          <div className="criteria max mobile">{max_scale_text}</div>
          {max_scale_text && min_scale_text && (
              <div className="radio__criterias">
                <div className="criteria min">{min_scale_text}</div>
                <div className="criteria max">{max_scale_text}</div>
              </div>
          )}
          <ErrorMessage
              name={fieldName}
              render={(msg) => <p className="error-message">{msg}</p>}
          />
        </div>
      </>
  );
};

export default Range;
