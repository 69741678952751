import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Footer.scss";

const Footer = () => {
  const { pathname } = useLocation();
  const { campaign, loading } = useSelector((state) => state.singleCampaign);

  const createMarkup = (content) => {
    return { __html: content };
  };

  return (
    <footer className="footer">
      <div className="rights-reserved">
        All rights reserved. {new Date().getFullYear()}
      </div>
      {pathname.includes("campaign/") && !loading && campaign?.footer_text && (
        <div
          className="footer-text"
          dangerouslySetInnerHTML={createMarkup(campaign.footer_text)}
        ></div>
      )}
    </footer>
  );
};

export default Footer;
