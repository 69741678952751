import React, { useState } from "react";
import "./RegistrationFormField.scss";

import { ReactComponent as EyeIcon } from "assets/images/eye.svg";

const RegistrationFormField = ({ type, field, form, label }) => {
  const { touched, errors, values } = form;

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  if (type === "password" || passwordVisibility) {
    return (
      <>
        <div
          className={
            touched[field.name] && errors[field.name]
              ? "input__wrapper input__wrapper--error"
              : "input__wrapper"
          }
        >
          <input
            type={passwordVisibility ? "text" : type}
            className="registration__input"
            {...field}
          />
          <EyeIcon
            className={passwordVisibility ? "icon is-shown" : "icon"}
            onClick={() => setPasswordVisibility(!passwordVisibility)}
          />
          <label
            className={
              values[field.name] === ""
                ? "input-label"
                : "input-label input-label--active"
            }
          >
            {label}
          </label>
        </div>
        {touched[field.name] && errors[field.name] && (
          <div className="field__error">{errors[field.name]}</div>
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={
          touched[field.name] && errors[field.name]
            ? "input__wrapper input__wrapper--error"
            : "input__wrapper"
        }
      >
        <input type={type} className="registration__input" {...field} />
        <label
          className={
            values[field.name] === ""
              ? "input-label"
              : "input-label input-label--active"
          }
        >
          {label}
        </label>
      </div>
      {touched[field.name] && errors[field.name] && (
        <div className="field__error">{errors[field.name]}</div>
      )}
    </>
  );
};

export default RegistrationFormField;
