import axios from 'axios';
import { fork, put, takeLatest, call } from "redux-saga/effects";

import { LOGIN_REQUEST, REGISTRATION_REQUEST } from "redux/constants";
import { registrationSuccess, registrationError, loginSuccess, loginError, registrationRequest } from "redux/actions/auth";
import { LOGIN, REGISTRATION } from 'constants/api';
import ResponseErrorHandler from 'helpers/ResponseErrorHandler';
import saveUserCredentials from "helpers/SaveUserCredentials";
import { notification } from 'helpers/notification';


function* watchRegistration() {
    yield takeLatest(REGISTRATION_REQUEST, handleRegistration)
}

function* watchLogin() {
    yield takeLatest(LOGIN_REQUEST, handleLogin)
}

function* handleRegistration({ payload }, callback) {

    try {
        const { data } = yield call(() => axios.post(REGISTRATION, payload)
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            const authData = data.data;

            yield put(registrationSuccess(authData));
            yield saveUserCredentials(authData);
            callback();
        }

        if (data?.status === 'Error') {
            const err = new ResponseErrorHandler(data);

            yield put(registrationError(err.getValidationErrorMessages()));

            if (data?.error_code === "student_already_registered") {
                notification('error', `User with ${payload?.email} email is already been registered. You can log in with this email.`)
            } else {
                notification('error', err.getValidationErrorMessages());
            }
        }

    } catch (error) {
        yield put(registrationError(error))
    }
}

function* handleLogin({ payload }) {

    const { campaign_code } = payload;

    try {
        const { data } = yield call(() => axios.post(LOGIN, payload)
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            const authData = data.data;

            yield put(loginSuccess(authData));
            yield saveUserCredentials(authData);

            if (campaign_code) {

                yield put(registrationRequest({
                    ...authData,
                    campaign_code: campaign_code
                }))

                localStorage.removeItem('campaign_code');
            }

        } else if (data?.status === 'Error') {
            const err = new ResponseErrorHandler(data);

            yield put(loginError(err.getValidationErrorMessages()));

            notification('error', err.getValidationErrorMessages())
        }

    } catch (error) {
        yield put(loginError(error))
    }
}

export default function* authSagas() {
    yield fork(watchRegistration);
    yield fork(watchLogin);
}