export const observerItemColors = [
    {
        background_color: 'linear-gradient(0deg, rgba(63, 86, 211, 0.15), rgba(63, 86, 211, 0.15)), #FFFFFF',
        status_color: 'rgba(63, 86, 211, 0.2)',
        text_color: 'rgba(63, 86, 211)'
    },
    {
        background_color: 'linear-gradient(0deg, rgba(132, 76, 187, 0.15), rgba(132, 76, 187, 0.15)), #FFFFFF',
        status_color: 'rgba(132, 76, 187, 0.2)',
        text_color: 'rgba(132, 76, 187)'
    },
    {
        background_color: 'linear-gradient(0deg, rgba(58, 151, 219, 0.15), rgba(58, 151, 219, 0.15)), #FFFFFF',
        status_color: 'rgba(58, 151, 219, 0.2)',
        text_color: 'rgba(58, 151, 219)'
    },
    {
        background_color: 'linear-gradient(0deg, rgba(224, 112, 77, 0.15), rgba(224, 112, 77, 0.15)), #FFFFFF',
        status_color: 'rgba(224, 112, 77, 0.2)',
        text_color: 'rgba(224, 112, 77)'
    },
    {
        background_color: 'linear-gradient(0deg, rgba(206, 82, 104, 0.15), rgba(206, 82, 104, 0.15)), #FFFFFF',
        status_color: 'rgba(206, 82, 104, 0.2)',
        text_color: 'rgba(206, 82, 104)'
    },
    {
        background_color: 'linear-gradient(0deg, rgba(60, 168, 97, 0.15), rgba(60, 168, 97, 0.15)), #FFFFFF',
        status_color: 'rgba(60, 168, 97, 0.2)',
        text_color: 'rgba(60, 168, 97)'
    }
]