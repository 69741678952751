import React from "react";
import { ErrorMessage } from "formik";
import QuestionDetails from "components/Forms/QuestionnaireForm/FormSteps/QuestionDetails";

const TextStep = ({ question, placeholder, name, description, form, field }) => {
  const fieldName = name || field.name;

  const { setFieldValue } = form;

  return (
    <>
        <div className="textarea-form">
            <QuestionDetails question={question} description={description} />
            <textarea
                className="textarea"
                name={fieldName}
                placeholder={placeholder}
                id="textarea"
                cols="30"
                rows="10"
                onChange={(e) => setFieldValue(fieldName, e.target.value)}
                value={field.value !== "" ? field.value : ''}
            ></textarea>
        </div>
        <ErrorMessage
            name={fieldName}
            render={(msg) => <p className="error-message">{msg}</p>}
      />
    </>
  );
};

export default TextStep;
