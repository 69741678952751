import React from 'react';
import './ObserversInfo.scss';

import requiredIcon from 'assets/images/required_type.svg';
import totalIcon from 'assets/images/total_by_type.svg';
import notRequiredIcon from 'assets/images/not-required-icon.svg';

const ObserversInfo = ({ type, observers }) => {
  const numberOfExistingObservers = (observers) => {
    if (observers.length < 2) {
      return observers.length;
    }
    return 2;
  };

  return (
    <div className='observers-type__info'>
      {type?.required ? (
        <>
          <div className='info__required'>
            <img src={requiredIcon} alt='requiredIcon' /> Required:{' '}
            {numberOfExistingObservers(observers)} of 2
          </div>
          <div className='info__total'>
            <img src={totalIcon} alt='requiredIcon' /> Total:{' '}
            {observers?.length}
          </div>
        </>
      ) : (
        <div className='not-required'>
          <img src={notRequiredIcon} alt='notRequiredIcon' />
          <div className='text__content'>
            <div className='text'>This observer type is not required.</div>
            <div className='text'>
              To add observers to a category, you must add at least 2 and no
              more than 4 persons.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ObserversInfo;
