class ResponseErrorHandler {
    constructor({
        status = 'Error',
        message = 'Server error',
        error_code,
        errors
    }) {

        // if (error_code === 'request_unauthorized') {
        //     throw new Error(error_code);
        // }

        this.status = status;
        this.message = message;
        this.error_code = error_code;
        this.errors = errors;
    }

    getStatus() {
        return this.status;
    }

    getMessage() {
        return this.message;
    }

    getErrorCode() {
        return this.error_code;
    }

    getErrors() {
        return this.errors;
    }

    getValidationErrorMessages() {
        if (this.errors?.length) {

            return this.errors.map(error => error.message).join(` \n`);
        }

        return this.message;
    }

    getValidationErrorCustomMessages() {
        if (this.errors?.length) {
            return this.errors.map((error) => {
                if (error.field.includes('email')) {
                    const index = error.field.match(/\d+/)[0];

                    return `The observers ${+index + 1} email field has a duplicate value.`
                }
                return ''
            }).join(` \n`);
        }

        return this.getValidationErrorMessages();
    }
}

export default ResponseErrorHandler;
