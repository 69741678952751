import React from 'react';
import './SuccessMessage.scss';

import successIcon from "assets/images/success__icon.svg";

const SuccessMessage = () => {
  return (
    <div className='success-message__container'>
      <img className='content__icon' src={successIcon} alt='successIcon' />
      <div className='content__title'>Your Answers Were Saved Successfully</div>
    </div>
  );
}

export default SuccessMessage