import React from "react";
import SendIcon from "components/UI/Icons/SendIcon";
import FilledIcon from "components/UI/Icons/FilledIcon";
import MyButton from "components/UI/Button/MyButton";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";

import "./ObserverItem.scss";

const ObserverItem = ({
  name,
  surname,
  email,
  date,
  colors,
  isAnswered,
  invitationLink,
}) => {
  const { background_color, text_color, status_color } = colors;
  const [copyToClipboard] = useCopyToClipboard();

  const handleCopyToClipboard = () => {
    copyToClipboard(invitationLink);
  };

  return (
    <div className='observer-item' style={{ background: background_color }}>
      <div
        className='invitation__status'
        style={{ backgroundColor: status_color }}
      >
        {isAnswered ? (
          <FilledIcon color={text_color} />
        ) : (
          <SendIcon color={text_color} />
        )}
        <span style={{ color: text_color }}>
          {isAnswered ? "Assessment given" : "Invitation sent"}
        </span>
      </div>
      <div className='observer__name' style={{ color: text_color }}>
        {name} {surname}
      </div>
      <div className='observer__email' style={{ color: text_color }}>
        {email}
      </div>
      <div className='invitation__date' style={{ color: text_color }}>
        Invitation was sent on {date}
      </div>
      {invitationLink && (
        <MyButton
          className='observer-invitation'
          style={{ background: status_color, color: text_color }}
          onClick={handleCopyToClipboard}
        >
          Copy invitation link
        </MyButton>
      )}
    </div>
  );
};

export default ObserverItem;
