import React from "react";
import "./SuccessModalContent.scss";

import closeIcon from "assets/images/close_icon.svg";
import MyButton from "components/UI/Button/MyButton";
import SuccessMessage from "components/SuccessMessage/SuccessMessage";

const SuccessModalContent = ({ setActive }) => {
  return (
    <div className="success-modal__wrapper">
      <img
        className="close__btn"
        src={closeIcon}
        alt="close-icon"
        onClick={() => setActive(false)}
      />
      <div className="success-message__content">
        <SuccessMessage />
        <div className="btn__wrapper">
          <MyButton onClick={() => setActive()} className="continue">Ok, got it</MyButton>
        </div>
      </div>
    </div>
  );
};

export default SuccessModalContent;
