import React from "react";
import MyButton from "components/UI/Button/MyButton";
import dueDate from "assets/images/due_date.svg";
import MyLink from "components/UI/Link/MyLink";
import "./CampaignItem.scss";

const CampaignItem = ({
  image,
  title,
  description,
  date,
  status,
  to,
  inviteLink,
  registeredAt,
}) => {
  if (!registeredAt) {
    return (
      <div className='campaign__wrapper not-registered'>
        <div className='campaign__image'>
          <img src={image} alt='campaignImage' />
        </div>
        <div className='campaign__content'>
          <div className='campaign__title'>You’ve been invited to {title}</div>
          <div className='campaign__description'>{description}</div>
          <div className='campaign__info'>
            {date && (
              <MyButton className='due-date'>
                <img src={dueDate} className='due-date_image' alt='' />
                Due date: {date}
              </MyButton>
            )}
            <a href={inviteLink} className='register-link'>
              <MyButton className='progress-status'>Accept</MyButton>
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <MyLink className='campaign' to={to}>
      <div className='campaign__wrapper'>
        <div className='campaign__image'>
          <img src={image} alt='campaignImage' />
        </div>
        <div className='campaign__content'>
          <div className='campaign__title'>{title}</div>
          <div className='campaign__description'>{description}</div>
          <div className='campaign__info'>
            <MyButton className='due-date'>
              <img src={dueDate} className='due-date_image' alt='' />
              Due date: {date}
            </MyButton>
            <MyButton
              className={
                status === "Finished"
                  ? "progress-status finished"
                  : "progress-status"
              }
            >
              {status}
            </MyButton>
          </div>
        </div>
      </div>
    </MyLink>
  );
};

export default CampaignItem;
