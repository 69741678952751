import React from "react";
import { Formik, Form, Field } from "formik";
import "./AuthForms.scss";
import MyButton from "components/UI/Button/MyButton";
import RegistrationFormField from "../FormFields/RegistrationFormField/RegistrationFormField";
import { signInSchema } from "../ValidationSchemas/AuthFormsValidationSchemas";
import { useActions } from "hooks/useActions";
import { useState } from "react";
import { useSelector } from "react-redux";
import ResponseError from "components/ResponseError/ResponseError";

const SignInForm = () => {
  const [showResponseError, setShowResponseError] = useState(false);
  const { loginRequest } = useActions();

  const { loginError } = useSelector((state) => state.auth);

  const campaign_code = localStorage.campaign_code;

  return (
    <div className="auth-form__wrapper">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={signInSchema}
        onSubmit={(values) => {
          loginRequest({
            ...values,
            campaign_code: campaign_code
          });
          setShowResponseError(true);
          if (loginError) {
            setTimeout(() => {
              setShowResponseError(false);
            }, 5000);
          }
        }}
      >
        {({ dirty, isValid }) => (
          <Form className="auth-form">
            <Field
              name="email"
              type="email"
              label="Email"
              component={RegistrationFormField}
            />
            <Field
              name="password"
              type="password"
              label="Password"
              component={RegistrationFormField}
            />
            {showResponseError && loginError && (
              <ResponseError error={loginError} />
            )}
            <MyButton
              type="submit"
              className="create-account"
              disabled={!dirty || !isValid}
            >
              ENTER TO MY ACCOUNT
            </MyButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignInForm;
