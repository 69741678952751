import React from "react";
import "./TitleSection.scss";
import arrow from "assets/images/arrow_left.svg";
import MyButton from "components/UI/Button/MyButton";
import { useNavigate, useLocation } from "react-router-dom";

const TitleSection = ({ title, description, image, withImage = false }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <section
      className={
        image || withImage
          ? "title__section title__section--image"
          : "title__section"
      }
    >
      {state?.previousPage && (
        <div className="back__button">
          <MyButton className="back" onClick={() => navigate(-1)}>
            <img className="button__arrow" src={arrow} alt="arrow" />
            Back
          </MyButton>
        </div>
      )}

      <div
        className={
          image || withImage
            ? "section__content section__content--image"
            : "section__content"
        }
      >
        <div
          className={
            withImage
              ? "content__text content__text--with-image"
              : "content__text"
          }
        >
          <h1 className="section__title">{title}</h1>
          <div className="section__description">{description}</div>
        </div>
        {image && (
          <div className="content__image">
            <img src={image} alt="" />
          </div>
        )}
      </div>
    </section>
  );
};

export default TitleSection;
