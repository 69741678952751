import React from 'react';
import './ResponseError.scss';

const ResponseError = ({error}) => {
    return (
        <div className='response-error'>
            {error}
        </div>
    )
}

export default ResponseError
