import { routePaths } from "constants/routes";
import Registration from "pages/Registration/Registration";
import SignIn from "pages/SignIn/SignIn";
import Home from "pages/Home/Home";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import CheckEmail from "pages/ForgotPassword/CheckEmail/CheckEmail";
import CreatePassword from "pages/CreatePassword/CreatePassword";
import CampaignPage from "pages/CampaignPage/CampaignPage";
import Questionnaire from "pages/Questionnaire/Questionnaire";
import Observers from "pages/Observers/Observers";
import ObserverLandingPage from "pages/ObserverLandingPage/ObserverLandingPage";
import ObserverSurvey from "pages/ObserverSurvey/ObserverSurvey";

const { REGISTRATION, SIGN_IN, HOME, FORGOT_PASSWORD, CHECK_EMAIL, CREATE_PASSWORD, CAMPAIGN_PAGE, CAMPAIGN_QUESTIONNAIRE, CAMPAIGN_OBSERVERS, OBSERVER_LANDING_PAGE, OBSERVER_SURVEY } = routePaths;

export const privateRoutes = [
   { path: HOME, element: <Home /> },
   { path: CAMPAIGN_PAGE, element: <CampaignPage /> },
   { path: REGISTRATION, element: <Registration /> },
   { path: CAMPAIGN_QUESTIONNAIRE, element: <Questionnaire /> },
   { path: CAMPAIGN_OBSERVERS, element: <Observers /> }
]

export const publicRoutes = [
   { path: REGISTRATION, element: <Registration /> },
   { path: SIGN_IN, element: <SignIn /> },
   { path: FORGOT_PASSWORD, element: <ForgotPassword /> },
   { path: CHECK_EMAIL, element: <CheckEmail /> },
   { path: CREATE_PASSWORD, element: <CreatePassword /> },
   { path: OBSERVER_LANDING_PAGE, element: <ObserverLandingPage /> },
   { path: OBSERVER_SURVEY, element: <ObserverSurvey /> },
]