import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { privateRoutes, publicRoutes } from "./routes";
import { routePaths } from "constants/routes";
import SignIn from 'pages/SignIn/SignIn';

const AppRouter = () => {

    const { HOME } = routePaths;

    const { userToken } = useSelector(state => state.auth);

    return (
        <div className="main__wrapper">
            {
                userToken ?
                    <Routes>
                        {privateRoutes.map(route =>
                            <Route path={route.path} element={route.element} key={route.path} />
                        )}
                        <Route path='*' element={<Navigate to={HOME} />} />
                    </Routes>
                    :
                    <Routes>
                        {publicRoutes.map(route =>
                            <Route path={route.path} element={route.element} key={route.path} />
                        )}
                        <Route path='*' element={<SignIn />} />
                    </Routes>
            }
        </div>
    )
}

export default AppRouter;